import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import { Ul } from "./style"
import { Col } from "../components/main"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          image={
            post.frontmatter.image ||
            "https://storage.googleapis.com/writone-ja-admin/update-min.jpg"
          }
        />
        <Col style={{ padding: "1rem" }}>
          <h1>{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
          <MDXRenderer>{post.body}</MDXRenderer>
        </Col>
        {/* <div dangerouslySetInnerHTML={{ __html: post.html }} /> */}
        <hr
          style={{
            margin: `${rhythm(2)} 0 ${rhythm(1)}`,
          }}
        />
        {/* <Bio /> */}

        <Ul>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                ← 次の記事： {next.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                {previous.frontmatter.title}：前の記事 →
              </Link>
            )}
          </li>
        </Ul>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        image
      }
    }
  }
`
