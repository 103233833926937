import styled from "styled-components"

export const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  margin: 0;
  list-style: none;
  font-size: 1rem;
`
